import React from 'react';
import './VideoSection.css';

function VideoSection() {
    return (
        <div className="video-section">
            <h2>Watch Our Introduction</h2>
            <div className="video-container">
            <iframe
            src="https://www.youtube.com/embed/oLaDfr46IsQ?si=IVNMm8CRXvBK6aEU" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
             referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen></iframe>
            </div>
        </div>
    );
}

export default VideoSection;

import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      showButton();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <>
      <nav className='navbar'>
        <div className="navbar-container">
          <a href="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img
              src={process.env.PUBLIC_URL + '/symphonic_logo.png'}
              alt="Symphonic Logo"
              className="navbar-logo-image"
            />
            <h4 className='logo-name'>Symphonic Labs</h4>
          </a>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <a href="mailto:inquiries@symphoniclabs.com" className='nav-links-mobile' onClick={closeMobileMenu}>
                contact us
              </a>
            </li>
            
          </ul>
          {button && (
            <Button buttonStyle='btn--outline' href={"mailto:inquiries@symphoniclabs.com"}>
              contact us
            </Button>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;

import React, { useState, useEffect, useRef } from 'react';
import Typed from 'typed.js';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  const [isVideoPlaying] = useState(false);
  const typedElement = useRef(null);

  useEffect(() => {
    const options = {
      strings: [
        'Quieter than a whisper and faster than a keyboard.',
        'An interface you can use anywhere.'
      ],
      typeSpeed: 30,
      backSpeed: 30,
      backDelay: 4000,
      startDelay: 200,
      loop: true,
    };

    const typed = new Typed(typedElement.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  const downloadDmg = () => {
    window.location.href = 'https://drive.google.com/uc?export=download&id=1wFmRQ1kNKBurDgt0zurvFDpUnejRQoCD';
  };
  
  const redirectToDemo = () => {
    window.location.href = 'https://www.youtube.com/watch?v=oLaDfr46IsQ';
  };

  return (
    <div className={`hero-container ${isVideoPlaying ? 'dimmed' : ''}`}>
      <video src={process.env.PUBLIC_URL + '/videos/vid2.mp4'} autoPlay loop muted />
      <h1>the interface of the future</h1>
      <p ref={typedElement}></p>

      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={downloadDmg}
        >
          download
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={redirectToDemo}
        >
          demo <i className='far fa-play-circle' />
        </Button>
      </div>
      <div id='trailer-container'></div>
    </div>
  );
}

export default HeroSection;
